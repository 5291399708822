import { FC, PropsWithChildren } from 'react'
import { Global, ThemeProvider } from '@emotion/react'
import { cssGlobal } from '@app/styles/global.styles'
import { theme } from '@app/config/theme'

export const Providers: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Global styles={cssGlobal} />
    {children}
  </ThemeProvider>
)
