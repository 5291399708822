import { Providers } from '@app/providers'
import type { GatsbyBrowser, GatsbySSR } from 'gatsby'

import '@fontsource/ubuntu/400.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'

type WrapRootElement = GatsbyBrowser['wrapRootElement'] &
  GatsbySSR['wrapRootElement']

export const wrapRootElement: WrapRootElement = ({ element }) => (
  <Providers>{element}</Providers>
)
