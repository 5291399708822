import { theme } from '@app/config/theme'
import { css } from '@emotion/react'

const fonts = `
  @font-face {
    font-family: 'FantasiCeria';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(/fonts/FantasiCeria.woff) format('woff');
  }
`

export const cssGlobal = css`
  ${fonts}

  html {
    background-color: ${theme.colors.secondary};

    font-family: Ubuntu, sans-serif, serif;
    color: ${theme.colors.primary};
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};

    :hover {
      color: ${theme.colors.primary};
    }
  }

  body {
    margin: 0;
  }

  html ::-webkit-scrollbar {
    z-index: 99999;
    width: 1rem;
    height: 1rem;
  }

  html ::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: padding-box;
    border-radius: 0;
    background-color: ${theme.colors.secondary};

    :hover {
      background-color: ${theme.colors.primary};
    }

    :active {
      background-color: ${theme.colors.primary};
    }
  }
`
