export const theme = {
  colors: {
    primary: 'azure',
    secondary: 'black',
  },
  breakpoints: {
    desktop: 1366,
    laptop: 1200,
    tablet: 800,
  },
}
